<template>
  <div style="padding: 0 20px;box-sizing: border-box">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="留级" name="1">
        <LiuJi v-if="activeName === '1'" :is_sign="dataArr.is_sign" :sign_status="dataArr.sign_status"/>
      </el-tab-pane>
      <el-tab-pane label="休学" name="2">
        <XiuXue v-if="activeName === '2'"/>
      </el-tab-pane>
      <el-tab-pane label="集团间转学" name="3">
        <ZhuanXue v-if="activeName === '3'"/>
      </el-tab-pane>
      <el-tab-pane label="退学" name="4">
        <TuiXue v-if="activeName === '4'" :dropOutReason="dataArr.drop_out_reason"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import LiuJi from "@/views/moral/student-moral-management/status-management/Lists/LiuJi.vue";
import XiuXue from "@/views/moral/student-moral-management/status-management/Lists/XiuXue.vue";
import ZhuanXue from "@/views/moral/student-moral-management/status-management/Lists/ZhuanXue.vue";
import TuiXue from "@/views/moral/student-moral-management/status-management/Lists/TuiXue.vue";

export default {
  components: {TuiXue, ZhuanXue, XiuXue, LiuJi},
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      activeName: '1',
      dataArr: {
        is_sign: [],
        sign_status: [],
        drop_out_reason: []
      }
    }
  },
  created() {
    this.$_axios2.get('api/student/student-adjust/filter').then(res => {
      this.dataArr = res.data.data
    })
    this.handleClick()
  },
  activated() {
    this.handleClick()
  },
  methods: {
    handleClick() {
      let basePermissions = [{
        "id": 14832,
        "button": "新增",
        "type": "add",
        "url": ""
      }, {
        "id": 14837,
        "button": "导出数据",
        "type": "exportData",
        "url": ""
      }, {
        "id": 14837,
        "button": "查看",
        "type": "view",
        "url": ""
      }];
      if (Number(this.activeName) === 2) {
        basePermissions.push({
          "type": "resumeSchooling",
          "button": "复学",
          "id": 148387,
          "url": ""
        });
      }
      this.$store.commit("setButtonPermission", basePermissions);
    },
    getClass(params) {
      this.$_axios.get("/site/role-admin-class", {params}).then(res => {
        let {data} = res.data;
        if (data.length > 0) {
          data.unshift({
            class_id: "0",
            class_name: "全部"
          })
        }
      })
    },
  },
  computed: {
    ...mapState(["page"]),
    ...mapState(['buttonPermissions']),
  },
}
</script>

<style lang="scss" scoped>
</style>
